import React from 'react';
import logo from './components/myPic.png';
import mstar from './mstar.png';
import './App.css';
// Import the BrowserRouter, Route and Link components
import { BrowserRouter, Route, Link } from 'react-router-dom'; 
import { bounce, fadeIn, bounceInLeft, bounceInRight} from 'react-animations';
import Typography from 'typography';
import { TypographyStyle, GoogleFont } from 'react-typography';
import CodePlugin from 'typography-plugin-code';
import sternGroveTheme from 'typography-theme-stern-grove';
import gitHub from 'typography-theme-github';
// import Projects from './Projects.js'; 
// import Articles from './Articles.js'; 
// import About from './About.js'; 
import styled, { keyframes} from 'styled-components';
import {Instagram} from '@styled-icons/icomoon/Instagram';
import {Home} from '@styled-icons/fa-solid/Home'; 
import {University} from '@styled-icons/fa-solid/University';
import {Work} from '@styled-icons/material/Work';
const Title = styled.h1`
  font-size: 3em;
  text-align: left;
  color: #1976d2;
`;

const BlueInsta = styled(Instagram)`
`
const BlackHouse = styled(Home)`
`
const BlackUniversity = styled(University)`
`
const BlackWork = styled(Work)`
`
sternGroveTheme.plugins = [
  new CodePlugin(),
];

const typography = new Typography(sternGroveTheme);
const typographyGitHub = new Typography(gitHub);


const bounceAnimation = keyframes`${bounce}`;
const bounceInLeftAnimation = keyframes`${bounceInLeft}`;
const bounceInRightAnimation = keyframes`${bounceInRight}`;
const fadeInAnimation = keyframes`${fadeIn}`;

const BouncyDiv = styled.div`
  animation: 3s ${bounceAnimation};
`;

const BouncyInLeft = styled.div`
animation: 3s ${bounceInLeftAnimation};
`;

const BouncyInRight = styled.div`
animation: 3s ${bounceInRightAnimation};
`;

const FadeInDiv2 = styled.div`
  animation: 2s ${fadeInAnimation};
`;

const FadeInDiv5 = styled.div`
  animation: 5s ${fadeInAnimation};
`;

 
function App() {
  return (
    <BrowserRouter>
    <div class="topnav">
        <a class="active" href="#home">KB</a>
        <a href="#About">About</a>
        <a href="#Projects">Projects</a>
        <a href="#Contact">Contact</a>
    </div>
    <div class="row">
    <div class="main-logo">
        <div class="card">
        <BouncyInRight><FadeInDiv5><div class="logo-image">
        <img src={logo} alt="MyPic" />
        
        </div></FadeInDiv5></BouncyInRight>
        
        </div>
        
      </div>
      <div class="leftcolumn"><TypographyStyle typography={typographyGitHub} />
        <div class="card">
        <BouncyInLeft><FadeInDiv5><Title>Hi! I'm KONSTANTIN</Title></FadeInDiv5></BouncyInLeft>
        </div>
          <div class="card">
          <div class="contact-left"><BlackHouse size="35"  /></div><div class="contact-right"><p>Chicago, Illinois</p></div>
          </div>
          <div class="card">
          <div class="contact-left"><BlackUniversity size="35"  /></div><div class="contact-right"><p>University of Illinois at Urbana-Champaign</p></div>
          </div>
          <div class="card">
          <div class="contact-left"><BlackWork size="35"  /></div><div class="contact-right"><p>Software Developer at   </p></div><div class="contact-right"><img src={mstar} width="150" siz alt="MyPic" /></div>
          </div>
          
          <div class="card">
          <div class="contact-left"><BlueInsta size="35"  /></div><div class="contact-right"><p>Instagram</p></div>
        </div>
          {/* <h5>Title description, Dec 7, 2017</h5>
          <div class="fakeimg"><p>Image</p></div> */}
        
        <div class="card">
          <h2>TITLE HEADING</h2>
          <h5>Title description, Dec 7, 2017</h5>
        </div>
      </div>
      <div class="rightcolumn">
        <div class="card">
          <h2>About Me</h2>
        </div>
        <div class="card">
          <h3>Popular Post</h3>
          <div class="fakeimg"><p>Image</p></div>
          <div class="fakeimg"><p>Image</p></div>
          <div class="fakeimg"><p>Image</p></div>
        </div>
        <div class="card">
          <h3>Follow Me</h3>
          <p>Some text..</p>
        </div>
      </div>
    </div>
    </BrowserRouter>
  );
}
 
export default App;